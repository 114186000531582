export const home = require("../assets/image.png")
export const homePrimary = require("../assets/image(1).png")
export const acivity = require("../assets/bytesize_activity.png")
export const acivityPrimary = require("../assets/bytesize_activity(1).png")

export const inquiries = require("../assets/iconoir_help-circle.png")
export const inquiriesPrimary = require("../assets/iconoir_help-circle(1).png")
export const barcode = require("../assets/tabler_barcode.png")
export const barcodePrimary = require("../assets/tabler_barcode(1).png")
export const ticket = require("../assets/Ticket.png")
export const ticketPrimary = require("../assets/Ticket(1).png")
export const members = require("../assets/Usergroup(1).png")
export const membersprimary = require("../assets/Usergroup.png")
export const eye = require("../assets/eye.png")
export const trash = require("../assets/trash-2.png");
export const eodlogo = require("../assets/skip_frame.png")
export const noResultsFound = require("../assets/noResultsFound.png")
export const profilePic = require("../assets/profilePic.jpeg")
export const membersProfPic = require("../assets/membersProfImg.jpeg")
export const blueCheck = require("../assets/blueCheck.png")
export const deleteProf = require("../assets/deleteProf.png")
export const logout = require("../assets/logout.png")
export const profile = require("../assets/profile.png")
export const settings = require("../assets/settings.png")
export const profSuccessArt = require("../assets/profSuccessArt.png")
export const redX= require("../assets/redX.png")
export const upload = require("../assets/upload.png")
export const searchIcon = require("../assets/searchIcon.png")
export const noMembersFound = require("../assets/noMembersFound.png");
export const blankProfile = require("../assets/blank-profile.png");
export const membersIcon = require("../assets/members.png");
export const ticketIcon = require("../assets/newticket.png");
export const calenderIcon = require("../assets/calender.png");
export const noActivitiesImage = require("../assets/noResults.png");