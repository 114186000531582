import React from "react";

// Customizable Area Start
import { AppBar, Box, Drawer, Hidden, createStyles, withStyles, Toolbar, IconButton, Grid, List, ListItem, Typography, CircularProgress, Button, Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core"
import { Dropdown, MenuProps } from "antd"
import { Logo, Cart, Menu, logout, profile } from "./assets";
import { Link } from "react-router-dom";
import { blankProfile } from "../../dashboard/src/assets";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// Customizable Area End

import NavbarController, {Props} from "./Navbarcontroller.web";

export default class Navbar extends NavbarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLogoutModal = (classes: any) => {
    return (
      <Dialog classes={{ paper: classes.dialogContainer }} open={this.state.openLogoutModal} onClose={this.handleLogoutModal} aria-labelledby="form-dialog-title" >
        <DialogContent className={classes.logoutModal}>
          <DialogContentText className={classes.logoutModalText}>
            Are you sure you want to logout  of your account
          </DialogContentText>

          <DialogActions className={classes.logoutButtonContainer}>
            <Button onClick={this.handleLogoutModal} className={classes.logoutCancelButton}>
              <span>Cancel</span>
            </Button>
            <Button className={classes.logoutButton} onClick={this.handleLogout}>
              <span>Logout</span>
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }
  renderMobileDrawer = (classes: any, isLoggedin: boolean) => {
    return (
      <Drawer PaperProps={{
        style: {
          width: "248px",
          backgroundColor: "#2B65EC"
        }
      }} open={this.state.drawerOpen} onClose={this.handleToggle}>
        <div className={classes.sliderIconHeader}>
          <Link to={'/'}><img src={Logo} alt="" style={{ width: 70, height: "auto", marginLeft: "10px" }} /></Link>
        </div>
        <List>
          <ListItem button className={classes.listItemButton}>
            <Link className={this.state.currentPath === "/" ? classes.mobileNavLinkActiveText : classes.mobileNavLinkText} to={'/'}>Home</Link>
          </ListItem>
          <ListItem button className={classes.listItemButton}>
            <Link to={'/AdventureActivity'} className={this.state.currentPath === "/AdventureActivity" ? classes.mobileNavLinkActiveText : classes.mobileNavLinkText}>Adventure Activities</Link>
          </ListItem>
          <ListItem button className={classes.listItemButton}>
            <Link to={'/parkInformation'} className={this.state.currentPath === "/parkInformation" ? classes.mobileNavLinkActiveText : classes.mobileNavLinkText}>Park Information</Link>
          </ListItem>
          <ListItem button className={classes.listItemButton}>
            <Link to={'/EventsAndGroups'} className={this.state.currentPath === "/EventsAndGroups" ? classes.mobileNavLinkActiveText : classes.mobileNavLinkText}>Events & Groups</Link>
          </ListItem>
          <ListItem button className={classes.listItemButton}>
            <Link to={'/AnnualPass'} className={this.state.currentPath === "/AnnualPass" ? classes.mobileNavLinkActiveText : classes.mobileNavLinkText}>Annual Pass</Link>
          </ListItem>
          <ListItem button className={classes.listItemButton}>
            <Link to={'/DayPass'} className={classes.mobileNavLinkText}>Buy Entry Tickets</Link>
          </ListItem>
          {isLoggedin &&
            <ListItem button className={classes.listItemButton}>
              <Link to={'/Dashboard'} className={classes.mobileNavLinkText}>My Dashboard</Link>
            </ListItem>
          }
          <ListItem button className={classes.listItemButton}>
            {isLoggedin ?
              <Link to={''} onClick={this.handleLogoutOpen} className={classes.mobileNavLinkText}>Logout</Link> : <Link to={'/Login'} className={classes.mobileNavLinkText}>Login</Link>}
          </ListItem>

        </List>
      </Drawer>
    )
  }
  renderLoginContainer = (classes: any, isLoggedin: boolean, items: any, primaryMember: any) => {
    return (
      <>
        {isLoggedin ?
          <Dropdown overlayClassName={classes.dropdownNavbar} menu={{ items, onClick: this.handleButtonClick }} trigger={['click']} placement="bottomRight" arrow={{ pointAtCenter: true }}

          >
            <div data-testid="dropDown" className={classes.profileContainer} onClick={(e) => e && e.preventDefault()}>
              <LazyLoadImage
                threshold={200}
                effect="blur"
                src={primaryMember.profile_url === null || primaryMember.profile_url === "No Image" ? blankProfile : primaryMember.profile_url} alt="" className={classes.profileImage}
              />
            </div>
          </Dropdown>
          :
          <Link to={'/Login'}>
            <Button className={classes.loginButton}>
              Log in
            </Button>
          </Link>
        }
      </>
    )
  }

  renderNavLinkContainer = (classes: any, items: any) => {
    const { primaryMember, isLoggedin } = this.state
    return (
      <Box className={classes.navLinkContainer}>
        <Box className={classes.navLink} >
          <Box className={classes.activeNavLink} style={styles.nonActiveTab}></Box>
          <Link className={this.state.currentPath === "/" ? classes.activeNavLinkText : classes.navLinkText} style={this.state.currentPath === "/" ? styles.activeTabFont : {}} to={'/'}>Home</Link>
          <Box className={classes.activeNavLink} style={this.state.currentPath === "/" ? styles.activeTab : styles.nonActiveTab}></Box>
        </Box>
        <Box className={classes.navLink} >
          <Box className={classes.activeNavLink} style={styles.nonActiveTab}></Box>
          <Link className={classes.navLinkText} to={'/AdventureActivity'} style={this.state.currentPath === "/AdventureActivity" ? styles.activeTabFont : {}}>Adventure Activities</Link>
          <Box className={classes.activeNavLink} style={this.state.currentPath === "/AdventureActivity" ? styles.activeTab : styles.nonActiveTab}></Box>
        </Box>
        <Box className={classes.navLink} >
          <Box className={classes.activeNavLink} style={styles.nonActiveTab}></Box>
          <Link className={classes.navLinkText} to={'/parkInformation'} style={this.state.currentPath === "/parkInformation" ? styles.activeTabFont : {}}>Park Information</Link>
          <Box className={classes.activeNavLink} style={this.state.currentPath === "/parkInformation" ? styles.activeTab : styles.nonActiveTab}></Box>
        </Box>
        <Box className={classes.navLink} >
          <Box className={classes.activeNavLink} style={styles.nonActiveTab}></Box>
          <Link className={classes.navLinkText} to={'/EventsAndGroups'} style={this.state.currentPath === "/EventsAndGroups" ? styles.activeTabFont : {}}>Events & Groups</Link>
          <Box className={classes.activeNavLink} style={this.state.currentPath === "/EventsAndGroups" ? styles.activeTab : styles.nonActiveTab}></Box>
        </Box>
        <Box className={classes.navLink} >
          <Box className={classes.activeNavLink} style={styles.nonActiveTab}></Box>
          <Link className={classes.navLinkText} to={'/AnnualPass'} style={this.state.currentPath === "/AnnualPass" ? styles.activeTabFont : {}}>Annual Pass</Link>
          <Box className={classes.activeNavLink} style={this.state.currentPath === "/AnnualPass" ? styles.activeTab : styles.nonActiveTab}></Box>
        </Box>
        <Link to={'/Cart'} style={!isLoggedin ? {pointerEvents:"none"}:{}}>
          <div className={classes.cartIconContainer}>
            {this.state.totalCartCount > 0 && isLoggedin && (
              <div className={classes.cartCount}>
                {this.state.totalCartCount}
              </div>
            )}
            <img src={Cart} alt="" className={classes.cartStyle} id="cart" />
          </div>
        </Link>
        <Link to={'/DayPass'}>
          <button className={classes.buyTicketsButton}>
            Buy Entry Tickets
          </button>
        </Link>
        {this.state.loading ?
          <div className={classes.loadContainer}>
            <CircularProgress size={20} />
          </div>
          :
          this.renderLoginContainer(classes, isLoggedin, items, primaryMember)}
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const { isLoggedin } = this.state
    const items: MenuProps['items'] = [
      {
        key: '1',
        label: (
          <Link to={"/Dashboard"} data-testid="dashboardBtn" className={classes.menuButton}>

            <img src={profile} alt="My Profile" className={classes.menuIcon} />
            <Typography className={classes.menuText}>

              My Dashboard
            </Typography>

          </Link>
        ),
      },
      {
        key: '2',
        label: (
          <Box className={classes.menuButton}>

            <img src={logout} alt="Logout" className={classes.menuIcon} />
            <Typography className={classes.menuText}>

              Logout
            </Typography>

          </Box>
        ),
      },
    ];

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.renderLogoutModal(classes)}
        <Hidden mdUp >
          {this.renderMobileDrawer(classes, isLoggedin)}
          <AppBar elevation={2} position='static' style={{ backgroundColor: "#fff" }}>
            <Toolbar>
              <Grid container alignItems='center' style={{ marginBottom: 22 }}>
                <Grid item xs={2} className={classes.menuButtonContainer}>
                  <IconButton
                    edge="start"
                    aria-label='menu'
                    onClick={this.handleToggle}
                  >
                    <img src={Menu} className={classes.menu} />
                  </IconButton>
                </Grid>
                <Grid item xs={8} className={classes.logoContainer}>
                  <Link to={'/'}><img src={Logo} alt="" className={classes.logo} /></Link>
                </Grid>
                <Grid item xs={2} className={classes.cartContainer}>
                  <div className={classes.cartIconContainer}>
                    <Link to={'/Cart'} style={!isLoggedin ? {pointerEvents:"none"}:{}}>
                      {this.state.totalCartCount > 0 && isLoggedin && (
                        <div className={classes.cartCount}>
                          {this.state.totalCartCount}
                        </div>
                      )}
                      <img src={Cart} alt="" className={classes.cartStyle} id="cart" />
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Hidden smDown>
          <AppBar className={classes.navbar} position="sticky" elevation={3}>
            <Link to={'/'}><img src={Logo} alt="" className={classes.logoStyle} /></Link>
            {this.renderNavLinkContainer(classes, items)}
          </AppBar>
        </Hidden>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  activeTabFont: {
    color: "var(--primary, #2B65EC"
  } as React.CSSProperties,
  activeTab: {
    backgroundColor: "var(--primary, #2B65EC",
  } as React.CSSProperties,
  nonActiveTab: {
    backgroundColor: "transparent"
  } as React.CSSProperties,
}

const muistyles = (theme: any) =>
  createStyles({
    dialogContainer: {
      marginBottom: 400,
      borderRadius: 8,
      [theme.breakpoints.down(900)]: {
        marginBottom: 100,
      }
    },
    logoutModal: {
      height: 160,
      width: 529,
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 16,
      [theme.breakpoints.down(900)]: {
        width: "100%",
      }
    },
    logoutModalText: {
      color: "var(--black, #334155)",
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal"
    },
    logoutButtonContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 16
    },
    logoutButton: {
      height: "42px",
      width: "117px",
      border: "none",
      borderRadius: 8,
      color: "#FFF",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      backgroundColor: "var(--primary, #2B65EC)",
      '&:hover': {
        background: "rgba(43, 101, 236, 0.70)",
        color: "#FFF",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },
      textTransform: "none"
    },
    logoutCancelButton: {
      height: "42px",
      width: "97px",
      border: "1px solid var(--black, #334155)",
      borderRadius: 8,
      color: "var(--black, #334155)",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      backgroundColor: "#fff",
      '&:hover': {
        border: "1px solid #12171f",
        color: "#12171f",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },
      textTransform: "none"
    },
    dropdownNavbar: {
      zIndex: 1500,
      "& .ant-dropdown-arrow": {
        boxShadow: "3px 3px 4px -3px rgba(0, 0, 0, 0.46)"
      },
      "& .ant-dropdown-menu": {
        borderRadius: 12,
        padding: "10px 6px",
        boxShadow: "none",
        filter: "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))"
      }
    },
    menuButton: {
      height: 32,
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      alignItems: "center"
    },
    menuText: {
      color: "#64748B",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
    },
    menuIcon: {
      height: 24,
      width: 24,
    },
    loadContainer: {
      display: 'flex',
      alignItems: "center",
      justifyContent: "center",
      height: "48px",
      width: "48px",
      marginLeft: 35,
      [theme.breakpoints.down(1030)]: {
        marginLeft: 20,
      },
    },
    profileContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: "48px",
      gap: 6,
      cursor: "pointer",
      marginLeft: 35,
      [theme.breakpoints.down(1030)]: {
        marginLeft: 20,
      },
    },
    profileImage: {
      height: 48,
      width: 48,
      borderRadius: "50%",
      border: "2px solid #2B65EC",
      textAlign: "left",
    },
    navbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: "center",
      height: "126px",
      background: 'white',
      width: "100%",
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 24,
        paddingRight: 24
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 40,
        paddingRight: 40
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: 80,
        paddingRight: 80
      },
    },

    logoContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      padding: "13px 0px"
    },
    logo: {
      width: 70,
      height: "auto"
    },
    cartContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center"
    },
    menuButtonContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center"
    },
    menu:
    {
      height: 32,
      width: 32
    }
    ,
    logoStyle: {
      width: "100px",
      height: "auto",
      [theme.breakpoints.down('lg')]: {
        width: 80,
      },
      [theme.breakpoints.up('lg')]: {
        width: 100,
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: "center",
      margin: "auto 0px",
      padding: "auto 80px",
      width: "100%",
    },
    mobileNavLinkText: {
      color: "#fff",
      fontFamily: "Liberteen Medium",
      fontWeight: 400,
      fontSize: "12px",
    },
    mobileNavLinkActiveText: {
      color: "#fff",
      fontFamily: "Liberteen Medium",
      fontWeight: 500,
      fontSize: "16px",
    },
    sliderIconHeader: {
      height: 82,
      width: "100%",
      backgroundColor: "#fff",
      padding: "14px",
    },
    listItemButton: {
      height: 45,
      paddingLeft: 24
    },
    navLinkText: {
      color: "#828282",
      fontFamily: "Liberteen Medium",
      fontWeight: 400,
      fontSize: "14px",
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '16px',
      },
    },
    activeNavLinkText: {
      fontFamily: "Liberteen Medium",
      fontWeight: 400,
      fontSize: "16px",
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
      },
    },
    navLink: {
      marginRight: 20,
      display: "flex",
      flexDirection: "column",
      gap: "10px"
      // width:"100%"
    },
    navLinkContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: "center",
      alignContent: "left",
    },
    loginButton: {
      background: "var(--primary, #2B65EC)",
      color: "white",
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: "Montserrat, sans-serif",
      borderRadius: "5px",
      border: "none",
      height: 44,
      width: 98,
      textTransform: "none",
      "&:hover": {
        background: "rgba(43, 101, 236, 0.70)",
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
        height: 38,
        width: 85,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        height: 44,
        width: 98,
      },
    },
    buyTicketsButton: {
      background: "white",
      color: "var(--primary, #2B65EC)",
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: "Montserrat, sans-serif",
      borderRadius: "5px",
      border: "1px solid var(--primary, #2B65EC)",
      height: 44,
      width: 185,
      marginRight: "24px",
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        height: 38,
        width: 149,
        marginRight: 10,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
        height: 44,
        width: 185,
      },
    },
    activeNavLink: {
      width: "100%",
      height: "3px",
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px"
    },
    '@media(max-width: 1030px)': {
      navbar: {
        height: 100
      },
      navLink: {
        marginRight: 12
      }
    },
    cartIconContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      width: "36px",
      height: "36px",
      marginRight: "20px",
      paddingTop: "5px"
    },
    cartStyle: { width: "22px", height: "auto" },
    cartCount: {
      position: "absolute",
      right: "2",
      height: "14px",
      width: "14px",
      borderRadius: "50%",
      backgroundColor: "var(--primary, #2B65EC)",
      color: "white",
      fontSize: "11px",
      fontFamily: "'Montserrat'",
      fontWeight: 700,
      textAlign: "center",
      lineHeight: "14px"
    },
    '@media(max-width: 991px)': {
      cartIconContainer: {
        paddingTop: "3px",
        marginRight: "0px"
      }
    },
  });


export const StyledNavbar = withStyles(muistyles)(Navbar)
// Customizable Area End
