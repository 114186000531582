import React, { Component } from "react";
// Customizable Area Start
import { WithStyles, createStyles, withStyles } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
// Customizable Area End
// Customizable Area Start
export interface Props extends WithStyles {
  bgTransparent?: boolean,
  heading?: string,
  subHeading?: string,
  bgImage: string,
  otherHeading?: string,
  classes:  {[key:string]:string},
}
const headerStyles = () => createStyles({
  headerMainContainer: {
    height: "624px",
    width: "100%",
    position: "relative",
    "@media(max-width:1920px)": {
      height: "624px"
    },
    "@media(max-width:1440px)": {
      height: "476px",
    },
    "@media(max-width:991px)": {
      height: "400px",
    },
    "@media(max-width:600px)": {
      height: "304px"
    }
  },
  headerImage: {
    height: "624px",
    objectFit: "cover",
    "@media(max-width:1920px)": {
      height: "624px",
       objectFit:"fill"
    },
    "@media(max-width:1440px)": {
      height: "476px",
      objectFit:"fill"
    },
    "@media(max-width:991px)": {
      height: "400px",
      objectFit:"cover"
    },
    "@media(max-width:600px)": {
      height: "304px"
    }
  },
  headerText: {
    fontSize: "36px",
    fontWeight: 700,
    margin: "5px 0px",
    textAlign:"center",
    fontFamily: 'Liberteen Medium, sans-serif',
    color: "white",
    "@media(max-width:1920px)": {
      fontSize: "36px",
    },
    "@media(max-width:1440px)": {
      fontSize: "32px",
    },
    "@media(max-width:1280px)": {
      fontSize: "24px",
    },
    "@media(max-width:600px)": {
      fontSize: "16px",
    }
  },
  headerInfo: {
    fontSize: "20px",
    margin: "10px",
    fontWeight: 500,
    fontFamily: 'Montserrat, sans-serif',
    width: "50%",
    lineHeight: "25px",
    textAlign: "center",
    "@media(max-width:1920px)": {
      fontSize: "20px",
    },
    "@media(max-width:1440px)": {
      fontSize: "18px",
    },
    "@media(max-width:1280px)": {
      fontSize: "18px",
    },
    "@media(min-width:1920px)": {
      width: "730px"
    },
    "@media(min-width:1280px)": {
      width: "674px"
    },
    "@media(max-width:1024px)": {
      width: "513px",
    },
    "@media(max-width:600px)": {
      fontSize: "12px",
      lineHeight: "16px",
      width: "323px"
    }
  },
  orangeText: {
    fontSize: "36px",
    fontWeight: 700,
    margin: "5px 0px",
    fontFamily: 'Liberteen Medium, sans-serif',
    color: "var(--Yellow, #FFDE59)",
    "@media(max-width:1920px)": {
      fontSize: "36px",
    },
    "@media(max-width:1440px)": {
      fontSize: "32px",
    },
    "@media(max-width:1280px)": {
      fontSize: "24px",
    },
    "@media(max-width:600px)": {
      fontSize: "16px",
    }
  },
  headerTextContainer2: {
    padding: "10px 10px",
    position: "absolute",
    left: "50%",
    top: "50%",
    bottom: "0px",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px"
  },
  headerTextContainer: {
    padding: "10px 10px",
    position: "absolute",
    background:  "linear-gradient(0deg, rgba(43, 101, 236, 0.60) 0%, rgba(43, 101, 236, 0.60) 100%)",
    left: "50%",
    top: "50%",
    bottom: "0px",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px"
  },
})
class Header extends Component<Props> {
  render() {
    const { classes } = this.props
    const textContainer = this.props.bgTransparent?classes.headerTextContainer2 :classes.headerTextContainer
    return (
      <div className={classes.headerMainContainer} data-testid="headerMainContainer">
        {this.props.bgTransparent?
          <div >
            <img className={classes.headerImage} alt="bgImage" src={this.props.bgImage} width={"100%"}/>
          </div>
        :<LazyLoadImage
                  effect="blur"
                    threshold={200}
                    src={this.props.bgImage} alt="bgImage" className={classes.headerImage}
                    width={"100%"}
                  />}
        <div className={textContainer}>
          <p className={classes.headerText}>
            {this.props.otherHeading}
            <span className={classes.orangeText}>{this.props.heading}</span>
          </p>
          <p className={classes.headerInfo}>
            {this.props.subHeading}
          </p>
        </div>
      </div>
    );
  }
}
export default withStyles(headerStyles)(Header);
export { Header }
// Customizable Area End