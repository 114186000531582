Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.endPointForAnnualPass = "bx_block_custom_user_subs/annual_passes"
exports.endPointAddToCart = "bx_block_ticketsystem2/shopping_carts?token="
exports.headingTitle = "CHOOSE YOUR PRICING PLAN"
exports.signUpNow = "Signup Now!";
exports.description = "Login Or Sign up to Book your Pass!";
exports.loginNow = "Login Now";
// Customizable Area End