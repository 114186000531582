import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Helmet } from "react-helmet"

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SearchengineoptimisationseoController, {
  Props,
  configJSON,
} from "./SearchengineoptimisationseoController";

export default class Searchengineoptimisationseo extends SearchengineoptimisationseoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Helmet>
        <title>{this.props.title}</title>
        <link rel="canonical" href={this.props.url}></link>
        <meta name="keywords" content=" Things to do in Delhi,
Things to do Faridabad,
Things to do Noida,
Things to do ghaziabad,
Things to do indirapuram,
Family fun in Delhi,
family fun in noida,
family fun in ghaziabad,
kids play area,
outdoor kids play zone,
soft play area delhi,
soft play area noida,
soft play faridabad,
Thrill rides,
Delhi Adventure,
Trampoline Park,
Park Adventure,
Adventure Park in Delhi,
Eod Adventure Park Mayur Vihar,
Adventure Park Mayur Vihar,
Eod Mayur Vihar,
Adventure Park in Delhi ticket,
Recreational Games,
Recreational games for students,
Eod Park Mayur Vihar,
Delhi best amusement park,
Eod delhi,
Eod Adventure Park Delhi,
Adventure Park in Delhi ticket price,
Best adventure park in Delhi" />
        <meta name="description" content={this.props.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={this.props.title} />
        <meta name="twitter:description" content={this.props.description} />
        <meta name="twitter:image" content={this.props.imageUrl} />
        <meta property="og:title" content={this.props.title} />
        <meta property="og:description" content={this.props.description} />
        <meta property="og:image" content={this.props.imageUrl} />
        <meta property="og:url" content={this.props.url} />
        <meta property="og:site_name" content="e-o-d Adventure Park" />
        <meta name="fb_admins_meta_tag" content="eodadventure" />
        <meta property="fb:admins" content="eodadventure" />
      </Helmet>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
