import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { MenuProps } from "antd"
import { getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  history?: any;
  cartCount?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  drawerOpen: boolean
  token: string;
  errorMsg: string;
  loading: boolean;
  primaryMember: any;
  openLogoutModal: boolean;
  isLoggedin: boolean;
  currentPath: any;
  totalCartCount: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavbarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiLandingPageCallId: any = ""
  getLengthofCartItems: any = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CartMessageData),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      drawerOpen: false,
      token: "",
      errorMsg: "",
      loading: false,
      primaryMember: {},
      openLogoutModal: false,
      isLoggedin: false,
      currentPath: typeof window !== "undefined" ? window.location.pathname : "/",
      totalCartCount: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    await this.getAuthToken()

      this.getCountOfCartItems()
  
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.CartMessageData) === message.id) {
      if(message.properties.cartCount){
        this.getCountOfCartItems()
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const navbarMessageId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webrResponceJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (!webrResponceJson || webrResponceJson.errors) {

        this.handleErrorResponse(navbarMessageId, webrResponceJson);
        return;
      }

      this.handleSuccessfulResponse(navbarMessageId, webrResponceJson);
    }
    // Customizable Area End
  }
 

  // Customizable Area Start
  //istanbul ignore next/
  getAuthToken = async () => {
    try {
      const token = await getStorageData('LoginToken');
      if (token) {
        this.setState({ token: token },
          () => {
            
            this.getMemberData(this.state.token)
            this.getCountOfCartItems()
          })
      }
    } catch (error) {
      console.error('Error retrieving stored token:', error);
    }
  };
  handleErrorResponse = (webApiRequestCallId: string, webErrorReponse: any) => {
    const errorResponseActions: { [key: string]: Function } = {
      [this.apiLandingPageCallId]: () => {
        if (webErrorReponse.errors) {
          const handleInvalidToken = webErrorReponse.errors[0].token
          if (handleInvalidToken === 'Invalid token' || handleInvalidToken === 'Token has Expired') {
            this.handleLogout();
          }
        }
        this.setState({
          errorMsg: webErrorReponse,
          loading: false
        });
      },
      [this.getLengthofCartItems]: () => {
        this.setState({
          errorMsg: webErrorReponse,
          loading: false
        });
      },
    };

    const action = errorResponseActions[webApiRequestCallId];
    if (action) {
      action();
    }
  }

  handleSuccessfulResponse = (webApiRequestCallId: string, webResponseJson: any) => {
    const primaryMember = webResponseJson.data?.attributes
    const successfulResponseActions: { [key: string]: Function } = {
      [this.apiLandingPageCallId]: async() => {
        const  userData = await getStorageData("userData",true)
        if(userData===null){
         setStorageData("userData",JSON.stringify(primaryMember))
        }
        this.setState({
          errorMsg: "",
          loading: false,
          primaryMember: primaryMember,
          isLoggedin: true
        });
      },
      [this.getLengthofCartItems]: () => {
        if(webResponseJson?.data){
          this.setState({ totalCartCount: webResponseJson?.data.length })
        }else{
          this.setState({ totalCartCount: 0 })
        }
      },
    }

    const action = successfulResponseActions[webApiRequestCallId];
    if (action) {
      action();
    }
  }


  getMemberData = (token: string) => {
    this.setState({ loading: true })
    const webHeader = {
      "Content-Type": configJSON.membersReponseContentType,
      token: token,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLandingPageCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardApiEndPoint
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }

  handleToggle = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  handleLogoutModal = () => {
    this.setState({ openLogoutModal: false })
  }

  handleLogoutOpen = () => {
    this.setState({ drawerOpen: false, openLogoutModal: true })
  }
  handleLogout = async () => {
    await removeStorageData('LoginToken');
    this.setState({ openLogoutModal: false })
    window.setTimeout(() => {
      this.setState({ isLoggedin: false })
      this.props.history.push("/")
    }, 1000);
  };

  handleButtonClick: MenuProps['onClick'] = ({ key }: any) => {
    switch (key) {
      case '1':
        break;
      case '2':
        this.handleLogoutOpen()
        break;
      default:
        break;
    }
  };

  getCountOfCartItems = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const countRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLengthofCartItems = countRequest.messageId;

    countRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCartItemEndPoint}${this.state.token}`
    );
    countRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    countRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(countRequest.id, countRequest);
  };

  // Customizable Area End
}
